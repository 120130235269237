import React from "react";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";
import { graphql } from "gatsby";

const ArticleTemplate = ({
                           path,
                           data: {
                             strapiArticle: {
                               title,
                               author,
                               content,
                               created_at,
                               SeoTitle,
                               MetaDescriptions,
                               tags,
                               image,
                             },
                           },
                         }) => {

  return (
    <PageWithCmsWrapper
      breadcrumbTitle={"Blog"}
      createDate={created_at}
      path={path}
      title={title}
      author={author}
      content={content}
      image={image}
      seoProps={{ title: SeoTitle, description: MetaDescriptions, image: image }}
      tags={tags}
    />
  );
};

export default ArticleTemplate;


export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      title
      author {
        username
      }
      content
      created_at
      SeoTitle
      MetaDescriptions
      image
      tags {
        title
      }
    }
  }
`;
